import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import './App.css';

import Home from './Components/home/Home'
import Countries from './Components/countries/Countries';
import Country from './Components/country/Country';
import Loading from './Components/loading/Loading';

function App() {

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Loading/>
    },
    {
      path: '/countries',
      element: <Loading/>
    },
    {
      path:'/country/:id',
      element: <Loading/>
    },
    {
      path: '/l',
      element: <Loading/>
    }
  ])

  return (
    <div className="App">
      <RouterProvider router={router}/>
    </div>
  );
}

export default App;