import React from 'react'

import './navbar.css'

import lpdhLogo from '../../media/images/lpdhLogo.svg'

function Navbar() {
  return (
    <nav className='navbar'>
      <section className="navSection">
        <a className='navLink' href='/countries'>Países</a>
        <a className='navLink' href='/'>Texto</a>
        <a className='navLink' href='/'>Texto</a>
      </section>
      <section className="navSection navLogoCont">
        <a className='navLogo' href='/' >
          <img src={lpdhLogo} alt='Logo de La Piel del Hincha'/>
        </a>
      </section>
      <section className="navSection">
        <a className='navLink' href='/'>Texto</a>
        <a className='navLink' href='/'>Texto</a>
        <a className='navLink' href='/'>Texto</a>
      </section>
    </nav>
  )
}

export default Navbar