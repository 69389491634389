import React from 'react'

import './footer.css'

import lpdhLogo from '../../media/images/lpdhLogo.svg'
import planeIcon from '../../media/images/planeIcon.svg'
import premiumIcon from '../../media/images/premiumIcon.svg'
import secureIcon from '../../media/images/secureIcon.svg'

function Footer() {
    const userLanguage = navigator.language.split('-')[0];
    const languageText = JSON.parse(localStorage.languageText)

    return (
        <footer className='footer'>
            <section className='footerBannerCont'>
                <div className='footerBannerTextCont'>
                    <img src={secureIcon} alt=""/>
                    <span>{languageText.FOOTER_UPPER_BANNER_LEFT}</span>
                </div>
                <div className='footerBannerTextCont'>
                    <img src={planeIcon} alt=""/>
                    <span>{languageText.FOOTER_UPPER_BANNER_CENTER}</span>
                </div>
                <div className='footerBannerTextCont'>
                    <img src={premiumIcon} alt=""/>
                    <span>{languageText.FOOTER_UPPER_BANNER_RIGHT}</span>
                </div>
            </section>
            <ul className='footerCont'>
                <li className="footerSection">
                    <div className="footerLogo">
                        <img src={lpdhLogo} alt="Logo de La Piel del Hincha"/>
                    </div>
                    <h5 className='footerSectionTitle footerMobDisplay' style={{fontFamily:userLanguage==='ru'?'robotoRegular':'Logirent'}}>{languageText.FOOTER_CENTER_OTHERS}</h5>
                    <ul className='footerMobDisplay'>
                        <li className='footerMobDisplay'>
                            <a href="/" rel=''>{languageText.FOOTER_CENTER_CONTACT}</a>
                        </li>
                        <li className='footerMobDisplay'>
                            <a href="/" rel=''>{languageText.FOOTER_CENTER_TYC}</a>
                        </li>
                        <li className='footerMobDisplay'>
                            <a href="/" rel=''>{languageText.FOOTER_CENTER_RETURN_POLICY}</a>
                        </li>
                        <li className='footerMobDisplay'>
                            <a href="/" rel=''>{languageText.FOOTER_CENTER_ABOUT_US}</a>
                        </li>
                        <li className='footerMobDisplay'>
                            <a href="/" rel=''>{languageText.FOOTER_CENTER_FAQ}</a>
                        </li>
                        <li className='footerMobDisplay'>
                            <a href="/" rel=''>{languageText.FOOTER_CENTER_SIZE_GUIDE}</a>
                        </li>
                    </ul>
                </li>
                <li className="footerSection">
                    <h5 className='footerSectionTitle' style={{fontFamily:userLanguage==='ru'?'robotoRegular':'Logirent'}}>SECCIONES</h5>
                    <ul>
                        <li>
                            <a href="/countries" rel=''>Países</a>
                        </li>
                        <li>
                            <a href="/" rel=''>Camisetas</a>
                        </li>
                        <li>
                            <a href="/" rel=''>Jugador</a>
                        </li>
                        <li>
                            <a href="/" rel=''>Retro</a>
                        </li>
                        <li>
                            <a href="/" rel=''>Otros</a>
                        </li>
                        <li>
                            <a href="/" rel=''>Cómo comprar</a>
                        </li>
                        <li>
                            <a href="/" rel=''>Blog</a>
                        </li>
                    </ul>
                    <h5 className='footerSectionTitle footerMobDisplay' style={{fontFamily:userLanguage==='ru'?'robotoRegular':'Logirent'}}>{languageText.FOOTER_CENTER_SOCIAL_MEDIA}</h5>
                    <ul className='footerMobDisplay'>
                        <li>
                            <a className='tempImgCont footerMobDisplay' href="https://www.instagram.com/lapieldelhincha.store" target='_blank' rel='noopener noreferrer'>
                            <svg className='tempImg' alt="Página de Instagram de La Piel del Hincha" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 44 44">
                                    <g>
                                        <g>
                                            <circle fill="#FAD300" cx="22" cy="22" r="22"/>
                                        </g>
                                        <g>
                                            <g>
                                                <g>
                                                    <path d="M22,10.7c3.7,0,4.1,0,5.6,0.1c1.3,0.1,2.1,0.3,2.6,0.5c0.6,0.3,1.1,0.6,1.6,1c0.5,0.5,0.8,0.9,1,1.6
                                                        c0.2,0.5,0.4,1.2,0.5,2.6c0.1,1.5,0.1,1.9,0.1,5.6s0,4.1-0.1,5.6c-0.1,1.3-0.3,2.1-0.5,2.6c-0.3,0.6-0.6,1.1-1,1.6
                                                        c-0.5,0.5-0.9,0.8-1.6,1c-0.5,0.2-1.2,0.4-2.6,0.5c-1.5,0.1-1.9,0.1-5.6,0.1c-3.7,0-4.1,0-5.6-0.1c-1.3-0.1-2.1-0.3-2.6-0.5
                                                        c-0.6-0.3-1.1-0.6-1.6-1c-0.5-0.5-0.8-0.9-1-1.6c-0.2-0.5-0.4-1.2-0.5-2.6c-0.1-1.5-0.1-1.9-0.1-5.6s0-4.1,0.1-5.6
                                                        c0.1-1.3,0.3-2.1,0.5-2.6c0.3-0.6,0.6-1.1,1-1.6c0.5-0.5,0.9-0.8,1.6-1c0.5-0.2,1.2-0.4,2.6-0.5C17.9,10.7,18.3,10.7,22,10.7
                                                        M22,8.2c-3.8,0-4.2,0-5.7,0.1c-1.5,0.1-2.5,0.3-3.4,0.6c-0.9,0.4-1.7,0.8-2.4,1.6c-0.8,0.8-1.2,1.5-1.6,2.4
                                                        c-0.3,0.9-0.6,1.9-0.6,3.4c-0.1,1.5-0.1,1.9-0.1,5.7c0,3.8,0,4.2,0.1,5.7c0.1,1.5,0.3,2.5,0.6,3.4c0.4,0.9,0.8,1.7,1.6,2.4
                                                        c0.8,0.8,1.5,1.2,2.4,1.6c0.9,0.3,1.9,0.6,3.4,0.6c1.5,0.1,1.9,0.1,5.7,0.1c3.8,0,4.2,0,5.7-0.1c1.5-0.1,2.5-0.3,3.4-0.6
                                                        c0.9-0.4,1.7-0.8,2.4-1.6c0.8-0.8,1.2-1.5,1.6-2.4c0.3-0.9,0.6-1.9,0.6-3.4c0.1-1.5,0.1-1.9,0.1-5.7s0-4.2-0.1-5.7
                                                        c-0.1-1.5-0.3-2.5-0.6-3.4c-0.4-0.9-0.8-1.7-1.6-2.4c-0.8-0.8-1.5-1.2-2.4-1.6c-0.9-0.3-1.9-0.6-3.4-0.6
                                                        C26.2,8.2,25.8,8.2,22,8.2L22,8.2z"/>
                                                </g>
                                                <g>
                                                    <path d="M22,14.9c-3.9,0-7.1,3.2-7.1,7.1s3.2,7.1,7.1,7.1c3.9,0,7.1-3.2,7.1-7.1S25.9,14.9,22,14.9z M22,26.6
                                                        c-2.5,0-4.6-2.1-4.6-4.6s2.1-4.6,4.6-4.6s4.6,2.1,4.6,4.6S24.5,26.6,22,26.6z"/>
                                                </g>
                                                <g>
                                                    <circle cx="29.4" cy="14.6" r="1.7"/>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a className='tempImgCont footerMobDisplay' href="https://www.facebook.com/lapieldelhincha23" target='_blank' rel='noopener noreferrer'>
                                <svg className='tempImg' alt="Página de Facebook de La Piel del Hincha" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 44 44">
                                    <g>
                                        <g>
                                            <circle fill="#FAD300" cx="22" cy="22" r="22"/>
                                        </g>
                                        <g>
                                            <path d="M30.6,28.4l1-6.4h-6.1v-4.1c0-1.7,0.9-3.4,3.6-3.4h2.8V9c0,0-2.5-0.4-4.9-0.4c-5,0-8.3,3-8.3,8.5V22H13v6.4h5.6v15.4
                                                c1.1,0.2,2.3,0.3,3.4,0.3s2.3-0.1,3.4-0.3V28.4H30.6z"/>
                                        </g>
                                    </g>
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a className='tempImgCont footerMobDisplay' href="https://www.ppol.io/lapieldelhincha" target='_blank' rel='noopener noreferrer'>
                                <svg className='tempImg' alt="Perfil Ppol de La Piel del Hincha" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 44 44">
                                    <g>
                                        <circle fill="#FAD300" cx="22" cy="22" r="22"/>
                                        <g>
                                            <path d="M20.9,9.5H10.6c-0.1,0-0.2,0.1-0.1,0.2L22,22h11.4c0.1,0,0.2-0.1,0.1-0.2L22.8,10.4C22.3,9.8,21.6,9.5,20.9,9.5z"/>
                                            <path d="M22,22H10.6c-0.1,0-0.2,0.1-0.1,0.2l10.8,11.4c0.5,0.5,1.2,0.8,1.9,0.8h10.3c0.1,0,0.2-0.1,0.1-0.2L22,22z"/>
                                        </g>
                                    </g>
                                </svg>
                            </a>
                        </li>
                    </ul>
                </li>
                <li className="footerSection footerWebDisplay">
                    <h5 className='footerSectionTitle' style={{fontFamily:userLanguage==='ru'?'robotoRegular':'Logirent'}}>{languageText.FOOTER_CENTER_OTHERS}</h5>
                    <ul>
                        <li>
                            <a href="/" rel=''>{languageText.FOOTER_CENTER_CONTACT}</a>
                        </li>
                        <li>
                            <a href="/" rel=''>{languageText.FOOTER_CENTER_TYC}</a>
                        </li>
                        <li>
                            <a href="/" rel=''>{languageText.FOOTER_CENTER_RETURN_POLICY}</a>
                        </li>
                        <li>
                            <a href="/" rel=''>{languageText.FOOTER_CENTER_ABOUT_US}</a>
                        </li>
                        <li>
                            <a href="/" rel=''>{languageText.FOOTER_CENTER_FAQ}</a>
                        </li>
                        <li>
                            <a href="/" rel=''>{languageText.FOOTER_CENTER_SIZE_GUIDE}</a>
                        </li>
                    </ul>
                </li>
                <li className="footerSection footerWebDisplay">
                    <h5 className='footerSectionTitle' style={{fontFamily:userLanguage==='ru'?'robotoRegular':'Logirent'}}>{languageText.FOOTER_CENTER_SOCIAL_MEDIA}</h5>
                    <ul>
                        <li>
                            <a className='tempImgCont' href="https://www.instagram.com/lapieldelhincha.store" target='_blank' rel='noopener noreferrer'>
                                <svg className='tempImg' alt="Página de Instagram de La Piel del Hincha" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 44 44">
                                    <g>
                                        <g>
                                            <circle fill="#FAD300" cx="22" cy="22" r="22"/>
                                        </g>
                                        <g>
                                            <g>
                                                <g>
                                                    <path d="M22,10.7c3.7,0,4.1,0,5.6,0.1c1.3,0.1,2.1,0.3,2.6,0.5c0.6,0.3,1.1,0.6,1.6,1c0.5,0.5,0.8,0.9,1,1.6
                                                        c0.2,0.5,0.4,1.2,0.5,2.6c0.1,1.5,0.1,1.9,0.1,5.6s0,4.1-0.1,5.6c-0.1,1.3-0.3,2.1-0.5,2.6c-0.3,0.6-0.6,1.1-1,1.6
                                                        c-0.5,0.5-0.9,0.8-1.6,1c-0.5,0.2-1.2,0.4-2.6,0.5c-1.5,0.1-1.9,0.1-5.6,0.1c-3.7,0-4.1,0-5.6-0.1c-1.3-0.1-2.1-0.3-2.6-0.5
                                                        c-0.6-0.3-1.1-0.6-1.6-1c-0.5-0.5-0.8-0.9-1-1.6c-0.2-0.5-0.4-1.2-0.5-2.6c-0.1-1.5-0.1-1.9-0.1-5.6s0-4.1,0.1-5.6
                                                        c0.1-1.3,0.3-2.1,0.5-2.6c0.3-0.6,0.6-1.1,1-1.6c0.5-0.5,0.9-0.8,1.6-1c0.5-0.2,1.2-0.4,2.6-0.5C17.9,10.7,18.3,10.7,22,10.7
                                                        M22,8.2c-3.8,0-4.2,0-5.7,0.1c-1.5,0.1-2.5,0.3-3.4,0.6c-0.9,0.4-1.7,0.8-2.4,1.6c-0.8,0.8-1.2,1.5-1.6,2.4
                                                        c-0.3,0.9-0.6,1.9-0.6,3.4c-0.1,1.5-0.1,1.9-0.1,5.7c0,3.8,0,4.2,0.1,5.7c0.1,1.5,0.3,2.5,0.6,3.4c0.4,0.9,0.8,1.7,1.6,2.4
                                                        c0.8,0.8,1.5,1.2,2.4,1.6c0.9,0.3,1.9,0.6,3.4,0.6c1.5,0.1,1.9,0.1,5.7,0.1c3.8,0,4.2,0,5.7-0.1c1.5-0.1,2.5-0.3,3.4-0.6
                                                        c0.9-0.4,1.7-0.8,2.4-1.6c0.8-0.8,1.2-1.5,1.6-2.4c0.3-0.9,0.6-1.9,0.6-3.4c0.1-1.5,0.1-1.9,0.1-5.7s0-4.2-0.1-5.7
                                                        c-0.1-1.5-0.3-2.5-0.6-3.4c-0.4-0.9-0.8-1.7-1.6-2.4c-0.8-0.8-1.5-1.2-2.4-1.6c-0.9-0.3-1.9-0.6-3.4-0.6
                                                        C26.2,8.2,25.8,8.2,22,8.2L22,8.2z"/>
                                                </g>
                                                <g>
                                                    <path d="M22,14.9c-3.9,0-7.1,3.2-7.1,7.1s3.2,7.1,7.1,7.1c3.9,0,7.1-3.2,7.1-7.1S25.9,14.9,22,14.9z M22,26.6
                                                        c-2.5,0-4.6-2.1-4.6-4.6s2.1-4.6,4.6-4.6s4.6,2.1,4.6,4.6S24.5,26.6,22,26.6z"/>
                                                </g>
                                                <g>
                                                    <circle cx="29.4" cy="14.6" r="1.7"/>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a className='tempImgCont' href="https://www.facebook.com/lapieldelhincha23" target='_blank' rel='noopener noreferrer'>
                                <svg className='tempImg' alt="Página de Facebook de La Piel del Hincha" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 44 44">
                                    <g>
                                        <g>
                                            <circle fill="#FAD300" cx="22" cy="22" r="22"/>
                                        </g>
                                        <g>
                                            <path d="M30.6,28.4l1-6.4h-6.1v-4.1c0-1.7,0.9-3.4,3.6-3.4h2.8V9c0,0-2.5-0.4-4.9-0.4c-5,0-8.3,3-8.3,8.5V22H13v6.4h5.6v15.4
                                                c1.1,0.2,2.3,0.3,3.4,0.3s2.3-0.1,3.4-0.3V28.4H30.6z"/>
                                        </g>
                                    </g>
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a className='tempImgCont' href="https://www.ppol.io/lapieldelhincha" target='_blank' rel='noopener noreferrer'>
                                <svg className='tempImg' alt="Perfil Ppol de La Piel del Hincha" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 44 44">
                                    <g>
                                        <circle fill="#FAD300" cx="22" cy="22" r="22"/>
                                        <g>
                                            <path d="M20.9,9.5H10.6c-0.1,0-0.2,0.1-0.1,0.2L22,22h11.4c0.1,0,0.2-0.1,0.1-0.2L22.8,10.4C22.3,9.8,21.6,9.5,20.9,9.5z"/>
                                            <path d="M22,22H10.6c-0.1,0-0.2,0.1-0.1,0.2l10.8,11.4c0.5,0.5,1.2,0.8,1.9,0.8h10.3c0.1,0,0.2-0.1,0.1-0.2L22,22z"/>
                                        </g>
                                    </g>
                                </svg>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
            <section className='footerWhiteBannerCont'>
                <span>{languageText.FOOTER_LOWER_BANNER_CENTER}</span>
            </section>
        </footer>
  )
}

export default Footer