import React from 'react'

import './loading.css'

import lpdhLogo from '../../media/images/lpdhLogo.svg'

function Loading() {
  return (
    <div className='loadingContainer'>
        <div className='loadingLogoCont'>
            <img src={lpdhLogo}/>
        </div>
        <div className='loadingTextCont'>
            <span>l</span>
            <span>a</span>
            <span></span>
            <span>p</span>
            <span>i</span>
            <span>E</span>
            <span>l</span>
            <span></span>
            <span>d</span>
            <span>E</span>
            <span>l</span>
            <span></span>
            <span>h</span>
            <span>i</span>
            <span>n</span>
            <span>c</span>
            <span>h</span>
            <span>a</span>
        </div>
    </div>
  )
}

export default Loading