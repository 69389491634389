import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { makeRequest } from './axios';

import Navbar from './Components/navbar/Navbar';
import Footer from './Components/footer/Footer';
import Loading from './Components/loading/Loading';

const root = ReactDOM.createRoot(document.getElementById('root'));

function Main(){
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedLanguage = localStorage.getItem('languageText');
    const storedLanguageVersion = localStorage.getItem('languageTextVersion')

    if(storedLanguage && storedLanguageVersion===process.env.REACT_APP_TEXT_LANGUAGE_VERSION){
      setLoading(false)
    } else {
      const userLanguage = navigator.language.split('-')[0]
      getLanguageText(userLanguage)
    }
  }, [])

  const getLanguageText = async (language) => {
    try {
      const response = await makeRequest.get('getLanguageText.php', {params: {language: language}})
      localStorage.setItem('languageText', JSON.stringify(response.data))
      localStorage.setItem('languageTextVersion', process.env.REACT_APP_TEXT_LANGUAGE_VERSION)
      setLoading(false)
    }
    catch (error){
      console.error(error)
    }
  }

  if(loading){
    return <Loading/>
  }

  return(
    <React.StrictMode>
      <Navbar/>
      <App />
      <Footer/>
    </React.StrictMode>
  )
}

root.render(<Main/>)

/*root.render(
  <React.StrictMode>
    <Loading>
      <Navbar/>
      <App />
      <Footer/>
    </Loading>
  </React.StrictMode>
);*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
